// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./AuthProvider";
import "./App.css";
import { sharedRoutes } from "./SharedRoutes";
import Dashboard from "./container/MyPage/Dashboard";
import Login from "./components/Auth/Login";

const AppRoutes = () => {
  const { user } = useAuth();
  return (
    <Routes>
      {sharedRoutes.map((route) => {
        const element = React.createElement(route.component);
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.layout ? (
                <route.layout>{element}</route.layout>
              ) : (
                element
              )
            }
          />
        );
      })}
      <Route
        path="/login"
        element={user ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate to="/login" />}
      />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <AppRoutes />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
