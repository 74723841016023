import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newBusiness, setNewBusiness] = useState("");
  const [domain, setDomain] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
      return;
    }

    const uid = auth.currentUser.uid;
    getDoc(doc(db, "users", uid))
      .then((snap) => {
        if (snap.exists()) {
          const data = snap.data();
          setUserData(data);
          if (data.businesses?.length) setSelectedBusiness(data.businesses[0]);
        }
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }, [navigate]);

  const sanitizeDomain = (input) => {
    return input
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const handleBusinessNameChange = (value) => {
    setNewBusiness(value);
    setDomain(sanitizeDomain(value));
  };

  const handleAddBusiness = async () => {
    if (!newBusiness.trim() || !domain.trim()) return;

    const business = {
      name: newBusiness.trim(),
      domain: domain.trim() + ".biashara.online",
    };

    const userRef = doc(db, "users", auth.currentUser.uid);
    await updateDoc(userRef, {
      businesses: arrayUnion(business),
    });

    const updatedSnap = await getDoc(userRef);
    const updatedData = updatedSnap.data();
    setUserData(updatedData);
    setSelectedBusiness(business);
    setNewBusiness("");
    setDomain("");
    setShowModal(false);
  };

  const handleEditBusiness = async () => {
    const oldBusiness = userData.businesses[editingIndex];
    const newBusinessData = {
      name: newBusiness.trim(),
      domain: domain.trim() + ".biashara.online",
    };

    const userRef = doc(db, "users", auth.currentUser.uid);

    await updateDoc(userRef, {
      businesses: arrayRemove(oldBusiness),
    });

    await updateDoc(userRef, {
      businesses: arrayUnion(newBusinessData),
    });

    const updatedSnap = await getDoc(userRef);
    const updatedData = updatedSnap.data();
    setUserData(updatedData);
    setSelectedBusiness(newBusinessData);
    setEditMode(false);
    setShowModal(false);
    setNewBusiness("");
    setDomain("");
  };

  const openEditModal = (biz, index) => {
    setNewBusiness(biz.name);
    setDomain(biz.domain.replace(".biashara.online", ""));
    setEditingIndex(index);
    setEditMode(true);
    setShowModal(true);
  };

  const handleLogout = async () => {
    await auth.signOut();
    navigate("/login");
  };

  if (!userData) return <p>Loading...</p>;

  return (
    <div className="dashboard-container">
      <h1>Welcome!</h1>
      <button onClick={handleLogout}>Log out</button>

      <h2>My Business</h2>
      <select
        value={selectedBusiness?.name || ""}
        onChange={(e) => {
          const index = e.target.selectedIndex;
          if (e.target.value === "add_new") {
            setEditMode(false);
            setNewBusiness("");
            setDomain("");
            setShowModal(true);
          } else {
            setSelectedBusiness(userData.businesses[index]);
          }
        }}
      >
        {userData.businesses?.map((biz, idx) => (
          <option key={idx} value={biz.name}>
            {biz.name}
          </option>
        ))}
        <option value="add_new">➕ Add a new business</option>
      </select>

      {selectedBusiness && (
        <div style={{ marginTop: "20px" }}>
          <h3>{selectedBusiness.name}</h3>
          <p>
            Domain: <strong>{selectedBusiness.domain}</strong>
          </p>
          <button
            onClick={() =>
              openEditModal(
                selectedBusiness,
                userData.businesses.indexOf(selectedBusiness)
              )
            }
          >
            Edit
          </button>
        </div>
      )}

      {showModal && (
        <div className="modal">
          <h3>{editMode ? "Edit Business" : "Add New Business"}</h3>
          <input
            placeholder="Business name"
            value={newBusiness}
            onChange={(e) => handleBusinessNameChange(e.target.value)}
          />
          <input
            placeholder="Domain (without .biashara.online)"
            value={domain}
            onChange={(e) => setDomain(sanitizeDomain(e.target.value))}
          />
          <p>
            Full domain:{" "}
            <strong>
              {domain
                ? domain + ".biashara.online"
                : "yourdomain.biashara.online"}
            </strong>
          </p>
          <div className="modal-buttons">
            <button onClick={editMode ? handleEditBusiness : handleAddBusiness}>
              {editMode ? "Save Changes" : "Add Business"}
            </button>
            <button onClick={() => setShowModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
