import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBda9svi9T49imGFr6xmNyYEFDWqSwHUjg",
    authDomain: "biashara-online-a2998.firebaseapp.com",
    projectId: "biashara-online-a2998",
    storageBucket: "biashara-online-a2998.firebasestorage.app",
    messagingSenderId: "708832793340",
    appId: "1:708832793340:web:93afa4ea8049b95bb5bd79",
    measurementId: "G-EBCSC092XT"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
