import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <nav className="home-navbar">
        <div className="navbar-brand">
          <Link to="/" className="brand-link">
            Biashara.online
          </Link>
        </div>
        <div className="navbar-menu">
          <Link to="/dashboard" className="navbar-link">
            Dashboard
          </Link>
        </div>
      </nav>

      <header className="home-header">
        <h1 className="home-title">[yourbusiness].biashara.online</h1>
        <p className="home-subtitle">
          Empowering small businesses in Africa to build beautiful websites from
          their phones — no coding needed.
        </p>
        <div className="home-input-group">
          <input
            placeholder="Enter your business name"
            className="home-input"
          />
          <button className="home-button">Get Started</button>
        </div>
      </header>

      <section className="home-section">
        <h2 className="home-section-title">Why Choose Biashara.online?</h2>
        <div className="home-grid">
          <div className="home-card">
            <h3 className="home-card-title">Mobile First</h3>
            <p>
              Design and launch your business website directly from your phone.
            </p>
          </div>

          <div className="home-card">
            <h3 className="home-card-title">Easy to Use</h3>
            <p>
              No tech skills needed. Choose a template, add your details, and go
              live.
            </p>
          </div>

          <div className="home-card">
            <h3 className="home-card-title">Free & Affordable Plans</h3>
            <p>Start for free, then upgrade as your business grows.</p>
          </div>
        </div>
      </section>

      <section className="home-cta">
        <h2 className="home-cta-title">Launch Your Website Today</h2>
        <p className="home-cta-text">
          Join thousands of African entrepreneurs already using Biashara.online.
        </p>
        <button className="home-button large">Start Building</button>
      </section>

      <footer className="home-footer">
        &copy; {new Date().getFullYear()} Biashara.online — Empowering Africa’s
        entrepreneurs
      </footer>
    </div>
  );
};

export default Home;
